var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    staticClass: "titleFont text-center mt-5 bold-text black--text",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Update on an " + _vm._s(_vm.android ? "iOS" : "Android") + " Release ")]), _c('v-col', {
    staticClass: "titleFont bold-text",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|max:27',
      expression: "'required|max:27'"
    }],
    attrs: {
      "id": "editAppName",
      "counter": "27",
      "placeholder": "App name",
      "filled": ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('span', {
          staticClass: "bodyFont text-muted"
        }, [_vm._v("Please let us know your name")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', _vm.veeValidate('name', ''), false))], 1), _c('v-col', {
    staticClass: "titleFont bold-text",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|email',
      expression: "'required|email'"
    }],
    attrs: {
      "id": "editAppName",
      "placeholder": "Email",
      "filled": ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('span', {
          staticClass: "bodyFont text-muted"
        }, [_vm._v("Let us get back to you and keep your update")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }, 'v-text-field', _vm.veeValidate('email', ''), false))], 1), _c('v-col', {
    staticClass: "titleFont bold-text",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: '',
      expression: "''"
    }],
    attrs: {
      "rows": "4",
      "placeholder": "Message",
      "filled": ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('span', {
          staticClass: "bodyFont text-muted"
        }, [_vm._v("Let us know what's on your mind")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.message,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "message", $$v);
      },
      expression: "form.message"
    }
  }, 'v-textarea', _vm.veeValidate('message', ''), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "depressed": "",
      "loading": _vm.loading,
      "block": ""
    },
    on: {
      "click": _vm.sendReleaseRequest
    }
  }, [_vm._v(" Submit ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }