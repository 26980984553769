var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "titleFont bold-text text-center pa-5"
  }, [_vm._v(" " + _vm._s(_vm.fromModal ? "Install" : "Leave feedback") + " ")]), _c('h2', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.installInfo.name))]), _c('div', {
    staticClass: "text-center pt-5"
  }, [_vm.iosReleaseInfo ? _c('div', [_vm._v("Available for iOS")]) : _vm._e(), _vm.androidReleaseInfo ? _c('div', [_vm._v("Available for Android")]) : _vm._e()]), _c('v-row', [_c('v-col', {
    staticClass: "pr-0 pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('figure', {
    staticClass: "qrcode"
  }, [_c('qrcode', {
    attrs: {
      "value": _vm.shareLink,
      "options": {
        errorCorrectionLevel: 'Q',
        width: 200
      }
    }
  }), _c('img', {
    staticClass: "qrcode__image",
    attrs: {
      "src": _vm.image,
      "alt": _vm.installInfo.name
    },
    on: {
      "error": _vm.replaceDefaultAppImage
    }
  })], 1), _c('div', {
    staticClass: "bodyFont mt-0"
  }, [_vm._v(" Scan QR code from your mobile device to " + _vm._s(_vm.fromModal ? "install the app" : "leave feedback") + " ")]), _vm.androidReleaseInfo ? _c('div', {
    staticClass: "bodyFont mb-4"
  }, [_vm._v(" Or "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": _vm.androidReleaseInfo.url
    }
  }, [_vm._v("click here")]), _vm._v(" to download the APK file ")]) : _vm._e()]), _vm.fromModal ? _c('v-divider') : _vm._e(), _vm.fromModal ? _c('div', {
    staticClass: "text-muted mt-4"
  }, [_vm._v(" By installing, your location (country & city) and device information (model & version) will be shared with the developers of this app to help them to address your issues better. ")]) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }