<template>
    <div>
        <div class="titleFont bold-text text-center pa-5">
            {{ fromModal ? "Install" : "Leave feedback" }}
        </div>
        <h2 class="text-center">{{ installInfo.name }}</h2>
        <div class="text-center pt-5">
            <div v-if="iosReleaseInfo">Available for iOS</div>
            <div v-if="androidReleaseInfo">Available for Android</div>
        </div>
        <v-row>
            <v-col class="pr-0 pb-0" cols="12">
                <div class="text-center">
                    <figure class="qrcode">
                        <qrcode :value="shareLink" :options="{
                            errorCorrectionLevel: 'Q',
                            width: 200,
                        }"></qrcode>
                        <img class="qrcode__image" :src="image" :alt="installInfo.name" @error="replaceDefaultAppImage" />
                    </figure>
                    <div class="bodyFont mt-0">
                        Scan QR code from your mobile device to
                        {{ fromModal ? "install the app" : "leave feedback" }}
                    </div>
                    <div v-if="androidReleaseInfo" class="bodyFont mb-4">
                        Or
                        <a target="_blank" :href="androidReleaseInfo.url">click here</a> to
                        download the APK file
                    </div>
                </div>
                <v-divider v-if="fromModal"></v-divider>
                <div v-if="fromModal" class="text-muted mt-4">
                    By installing, your location (country & city) and device information
                    (model & version) will be shared with the developers of this app to
                    help them to address your issues better.
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        installInfo: {
            type: Object,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
        fromModal: {
            type: Boolean,
            default: false,
        },
        shareLink: {
            type: String,
            default: "",
        },
        iosReleaseInfo: {
            type: Object,
            default() {
                return null;
            },
        },
        androidReleaseInfo: {
            type: Object,
            default() {
                return null;
            },
        },
    },
};
</script>
