var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "background-color": "#f6f6f6"
    }
  }, [_c('div', {
    staticClass: "fill-width align-self-start"
  }, [_c('v-card', {
    staticClass: "d-flex align-center fill-width",
    attrs: {
      "color": "primary",
      "flat": "",
      "height": "50",
      "tile": ""
    }
  }, [_c('a', {
    staticClass: "pa-5",
    attrs: {
      "href": "https://testapp.io"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.fromExpress ? '/media/logos/logo_express.png' : '/media/logos/logo_light.svg',
      "alt": "",
      "height": "30"
    }
  })])])], 1), _c('v-container', [_vm.loading ? _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, _vm._l(4, function (i) {
    return _c('v-col', {
      key: i,
      staticClass: "mb-4",
      attrs: {
        "cols": "12",
        "md": "8"
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "list-item-avatar, divider, list-item-three-line, card-heading, image"
      }
    })], 1);
  }), 1) : _vm.errorMessage ? _c('v-row', {
    staticClass: "mb-15",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-card', {
    staticClass: "pa-10"
  }, [[_c('v-col', {
    staticClass: "text-center titleFont bold-text",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Ops! ")]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    attrs: {
      "color": "red",
      "label": "",
      "outlined": ""
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.errorMessage)
    }
  })])], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Please contact the person who shared this link with you for more info ")]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('img', {
    staticClass: "mx-auto my-auto",
    attrs: {
      "src": "/media/error/not_available.png",
      "width": "260"
    }
  })])]], 2)], 1)], 1) : _c('v-row', {
    staticClass: "mb-15",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_vm.isAuthenticated && _vm.canManageApp && _vm.installInfo.screenshots.length === 0 ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    attrs: {
      "color": "info",
      "dismissible": "",
      "outlined": ""
    }
  }, [_c('span', [_c('v-icon', [_vm._v("mdi-lightbulb-on-outline")]), _vm._v(" Customize this page from the Settings page")], 1), _c('span', {
    staticClass: "ml-2"
  }, [_c('VueTooltip', {
    attrs: {
      "v-html": 'This message is only visible to the app managers of this app. <br /><br />Add description, screenshots, and other info from App -> Share -> Settings page',
      "color": "primary",
      "icon-color": "grey",
      "text-class": "white--text"
    }
  })], 1)])], 1)], 1) : _vm._e(), _c('v-card', {
    staticClass: "px-8",
    attrs: {
      "flat": ""
    }
  }, [_vm.installInfo.settings.status ? _c('v-row', {
    staticClass: "pb-0",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-alert', {
    attrs: {
      "color": _vm.installInfo.settings.status == 'coming_soon' ? 'primary' : 'purple',
      "icon": _vm.installInfo.settings.status == 'coming_soon' ? 'mdi-clock' : 'mdi-alert',
      "outlined": ""
    }
  }, [_c('div', [!_vm.installInfo.settings.custom_message ? _c('span', [_vm._v(" " + _vm._s(_vm.get(_vm.installInfo, "settings.status", null) == "coming_soon" ? "This app will be soon available for public testing!" : "This app is in maintenance mode, please check back later") + " ")]) : _c('span', [_c('div', [_vm._v(" " + _vm._s(_vm.installInfo.settings.custom_message) + " "), _c('sub', [_c('small', [_vm._v("~ " + _vm._s(_vm.get(_vm.installInfo, "settings.developer_contact.name", _vm.installInfo.name + " app developers")))])])])])])])], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto",
      "md": "8"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('img', {
    attrs: {
      "alt": _vm.installInfo.name,
      "src": _vm.getAppImage(),
      "height": "100",
      "width": "100"
    },
    on: {
      "error": _vm.replaceDefaultAppImage
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "font-size-h2 bold-text",
    class: _vm.$vuetify.breakpoint.mdAndUp ? 'mb-1' : ''
  }, [_vm._v(" " + _vm._s(_vm.installInfo.name) + " ")]), _vm.get(_vm.installInfo, 'settings.developer_contact.name', null) ? _c('div', [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v("By")]), _c('span', [_vm._v(" " + _vm._s(_vm.get(_vm.installInfo, "settings.developer_contact.name", "-")) + " ")])]) : _vm._e()])], 1)], 1), _vm.mobile === 'unknown' ? _c('v-col', {
    staticClass: "text-center my-auto px-auto",
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "4",
      "sm": "4"
    }
  }, [!_vm.noReleases ? _c('div', [_c('v-btn', {
    attrs: {
      "block": "",
      "color": "primary",
      "x-large": ""
    },
    on: {
      "click": function click($event) {
        _vm.qrcodeModal = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-cellphone-arrow-down')
    }
  }), _vm._v(" Install ")], 1), _c('div', {
    staticClass: "mt-2 text-muted"
  }, [_vm._v(" Available for " + _vm._s(_vm.getIOSReleaseInfo && _vm.getAndroidReleaseInfo ? "both Android & iOS" : _vm.getIOSReleaseInfo ? "iOS" : "Android") + " ")])], 1) : _c('div', [_c('img', {
    staticClass: "errImg",
    attrs: {
      "src": _vm.get(_vm.installInfo, 'settings.status', null) ? '/media/error/maintenance.png' : '/media/error/not_available.png'
    }
  }), _c('div', {
    staticClass: "mt-2 text-muted"
  }, [_vm._v("No available releases")])])]) : _vm._e()], 1), _c('v-row', [_vm.installInfo.categories && _vm.installInfo.categories.length ? _c('v-col', {
    staticClass: "pt-0 mt-0",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, _vm._l(_vm.installInfo.categories, function (item, index) {
    return _c('v-chip', {
      key: index,
      staticClass: "mr-1 mt-0",
      attrs: {
        "x-small": ""
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('vue-readmore', {
    attrs: {
      "length": 200,
      "text": _vm.getAppDescription()
    }
  })], 1)], 1)], 1)], 1), _c('v-row', [_vm.noReleases && _vm.mobile === 'unknown' ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-right mt-3"
  }, [_vm.mobile === 'unknown' && (_vm.getIOSReleaseInfo || _vm.getAndroidReleaseInfo) ? _c('div', [!_vm.getIOSReleaseInfo || !_vm.getAndroidReleaseInfo ? _c('div', [_vm._v(" Available for " + _vm._s(_vm.getIOSReleaseInfo ? "iOS" : "Android") + " "), _c('v-menu', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "text-transform-none mb-2 cursor-pointer",
          attrs: {
            "icon": "",
            "small": "",
            "text": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "small": ""
          },
          domProps: {
            "textContent": _vm._s('mdi-help-circle')
          }
        })], 1)];
      }
    }], null, false, 142372532)
  }, [_c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "width": "300"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-information-outline')
    }
  })], 1), _c('v-col', {
    staticClass: "text-left black--text",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" This release is only available "), _c('br'), _vm._v(" for " + _vm._s(_vm.getIOSReleaseInfo ? "iOS" : "Android") + " ")])], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()]) : _vm._e()])]) : _vm._e()], 1), _vm.mobile === 'ios' && !_vm.getIOSReleaseInfo && !_vm.get(_vm.installInfo, 'settings.status', null) ? _c('v-row', [_c('v-col', {
    staticClass: "font-size-h3 bold-text text-center"
  }, [_vm._v(" This release isn’t available for your platform (iOS) yet ")]), _vm.getAndroidReleaseInfo ? _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" If you have Android device, use it to open this page or scan the below QR code "), _c('figure', {
    staticClass: "qrcode"
  }, [_c('qrcode', {
    attrs: {
      "options": {
        errorCorrectionLevel: 'Q',
        width: 200
      },
      "value": _vm.shareLink
    }
  }), _c('img', {
    staticClass: "qrcode__image",
    attrs: {
      "alt": _vm.installInfo.name,
      "src": _vm.getAppImage()
    },
    on: {
      "error": _vm.replaceDefaultAppImage
    }
  })], 1)]) : _vm._e()], 1) : _vm._e(), _vm.mobile === 'android' && !_vm.getAndroidReleaseInfo && !_vm.get(_vm.installInfo, 'settings.status', null) ? _c('v-row', [_c('v-col', {
    staticClass: "font-size-h3 bold-text text-center"
  }, [_vm._v(" This release isn’t available for your platform (Android) yet ")]), _vm.getIOSReleaseInfo ? _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" If you have iOS device, use it to open this page or scan the below QR code "), _c('figure', {
    staticClass: "qrcode"
  }, [_c('qrcode', {
    attrs: {
      "options": {
        errorCorrectionLevel: 'Q',
        width: 200
      },
      "value": _vm.shareLink
    }
  }), _c('img', {
    staticClass: "qrcode__image",
    attrs: {
      "alt": _vm.installInfo.name,
      "src": _vm.getAppImage()
    },
    on: {
      "error": _vm.replaceDefaultAppImage
    }
  })], 1)]) : _vm._e()], 1) : _vm._e(), _vm.mobile == 'unknown' ? _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-muted small"
  }, [_vm._v(" Having trouble installing? Read our "), _c('span', {
    staticClass: "text-decoration-underline primary--text cursor-pointer body-1",
    on: {
      "click": _vm.scrollToFAQ
    }
  }, [_vm._v(" FAQ ")])])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1) : _vm._e(), _vm.mobile !== 'unknown' && _vm.mobile === 'ios' && _vm.getIOSReleaseInfo || _vm.mobile === 'android' && _vm.getAndroidReleaseInfo ? _c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": _vm.mobile == 'android' ? 'success lighten-1' : 'primary lighten-1',
      "disabled": _vm.downloadProcess,
      "loading": _vm.busy,
      "x-large": ""
    },
    on: {
      "click": _vm.startDownload
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.mobile == 'android' ? 'mdi-android' : 'mdi-apple')
    }
  }), _vm._v(" " + _vm._s(_vm.downloadProcess ? "Downloading..." : "Download") + " ")], 1)], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-muted small"
  }, [_vm._v(" Having trouble installing? Read our "), _c('span', {
    staticClass: "text-decoration-underline primary--text body-1 cursor-pointer",
    on: {
      "click": _vm.scrollToFAQ
    }
  }, [_vm._v(" FAQ ")])])])], 1) : _vm._e(), _vm.installInfo.screenshots && _vm.installInfo.screenshots.length ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "screenShotContainer"
  }, _vm._l(_vm.installInfo.screenshots, function (item, index) {
    return _c('div', {
      key: index,
      staticStyle: {
        "display": "flex"
      }
    }, [_c('v-sheet', {
      staticClass: "imageBorder mr-2"
    }, [_c('v-img', {
      staticStyle: {
        "border-radius": "20px"
      },
      attrs: {
        "src": item,
        "cover": "",
        "height": "500",
        "width": "250"
      }
    })], 1)], 1);
  }), 0)])], 1) : _vm._e(), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_vm.mobile === 'unknown' && (_vm.getAndroidReleaseInfo || _vm.getIOSReleaseInfo) || _vm.mobile === 'android' && _vm.getAndroidReleaseInfo || _vm.mobile === 'ios' && _vm.getIOSReleaseInfo ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "font-size-h2 mb-3 bold-text"
  }, [_vm._v("Instructions")]), _c('vue-readmore', {
    attrs: {
      "length": 200,
      "text": _vm.installInfo.instructions || '-'
    }
  })], 1) : _vm._e()], 1), _c('v-row', [_vm.mobile === 'ios' && _vm.getIOSReleaseInfo ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "font-size-h2 bold-text"
  }, [_vm._v("Release Update")]), _c('vue-readmore', {
    attrs: {
      "length": 200,
      "text": _vm.getIOSReleaseInfo.note || '-'
    }
  })], 1) : _vm._e(), _vm.mobile === 'android' && _vm.getAndroidReleaseInfo ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "font-size-h2 bold-text"
  }, [_vm._v("Release Update")]), _c('vue-readmore', {
    attrs: {
      "length": 200,
      "text": _vm.getAndroidReleaseInfo.note || '-'
    }
  })], 1) : _vm._e()], 1), !_vm.noReleases ? _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-10",
    attrs: {
      "cols": "10"
    }
  }, [_c('v-divider')], 1)], 1) : _vm._e(), _c('v-row', [['android', 'unknown'].includes(_vm.mobile) ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "bodyFont text-muted"
  }, [_c('v-icon', {
    attrs: {
      "color": "grey",
      "right": "",
      "size": "20"
    },
    domProps: {
      "textContent": _vm._s('mdi-android')
    }
  }), _vm._v(" Android ")], 1)]), !_vm.getAndroidReleaseInfo ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Not available ")]) : [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "bold-text mb-2"
  }, [_vm._v("Store")]), _vm.get(_vm.installInfo, 'settings.store_links.android', '') ? _c('div', [_c('a', {
    attrs: {
      "href": _vm.get(_vm.installInfo, 'settings.store_links.android', '')
    }
  }, [_c('MaxText', {
    attrs: {
      "text": _vm.get(_vm.installInfo, 'settings.store_links.android', ''),
      "max": "18"
    }
  })], 1)]) : _c('div', [_vm._v("-")])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_c('div', {
    staticClass: "bold-text"
  }, [_vm._v("Package")]), _c('div', [_c('MaxText', {
    attrs: {
      "text": _vm.getAndroidReleaseInfo.package,
      "max": "15"
    }
  })], 1)])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_c('div', {
    staticClass: "bold-text mb-2"
  }, [_vm._v("Version")]), _c('div', [_c('MaxText', {
    attrs: {
      "text": _vm.getAndroidReleaseInfo.version,
      "max": "18"
    }
  })], 1)])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_c('div', {
    staticClass: "bold-text mb-2"
  }, [_vm._v("Version Code")]), _c('div', [_c('MaxText', {
    attrs: {
      "text": _vm.getAndroidReleaseInfo.version_code,
      "max": "18"
    }
  })], 1)])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "bold-text mb-2"
  }, [_vm._v("Size")]), _c('div', [_vm._v(" " + _vm._s(_vm.formatBytes(_vm.getAndroidReleaseInfo.size)) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_c('div', {
    staticClass: "bold-text mb-2"
  }, [_vm._v("Released")]), _c('div', [_c('vue-hoverable-date', {
    attrs: {
      "date": _vm.getAndroidReleaseInfo.created_at
    }
  })], 1)])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "bold-text mb-2"
  }, [_vm._v("Minimum SDK Version")]), _c('div', [_vm._v(" " + _vm._s(_vm.get(_vm.getAndroidReleaseInfo, "info.min_sdk_version", "")) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "bold-text mb-2"
  }, [_vm._v("Target SDK Version")]), _c('div', [_vm._v(" " + _vm._s(_vm.get(_vm.getAndroidReleaseInfo, "info.target_sdk_version", "")) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "bold-text mb-2"
  }, [_vm._v("Permissions")]), _vm.getAndroidReleaseInfo && _vm.getAndroidReleaseInfo.info && _vm.getAndroidReleaseInfo.info.permissions && _vm.getAndroidReleaseInfo.info.permissions.length ? _c('div', {
    staticClass: "primary--text cursor-pointer",
    on: {
      "click": _vm.showPermissionDialog
    }
  }, [_vm._v(" View permissions ")]) : _c('div', [_vm._v("-")])])]], 2)], 1) : _vm._e(), ['ios', 'unknown'].includes(_vm.mobile) ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "bodyFont text-muted"
  }, [_c('v-icon', {
    staticClass: "mb-1",
    attrs: {
      "color": "grey",
      "right": "",
      "size": "20"
    },
    domProps: {
      "textContent": _vm._s('mdi-apple')
    }
  }), _vm._v(" iOS ")], 1)]), !_vm.getIOSReleaseInfo ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Not available ")]) : _vm.getIOSReleaseInfo.testflight ? [_c('v-col', [_c('a', {
    attrs: {
      "href": _vm.getIOSReleaseInfo.testflight
    }
  }, [_vm._v(" Click here ")]), _vm._v(" to start testing in TestFlight ")])] : [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "bold-text mb-2"
  }, [_vm._v("Store")]), _vm.get(_vm.installInfo, 'settings.store_links.ios', '') ? _c('div', [_c('a', {
    attrs: {
      "href": _vm.get(_vm.installInfo, 'settings.store_links.ios', '')
    }
  }, [_c('MaxText', {
    attrs: {
      "text": _vm.get(_vm.installInfo, 'settings.store_links.ios', ''),
      "max": "18"
    }
  })], 1)]) : _c('div', [_vm._v("-")])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_c('div', {
    staticClass: "bold-text"
  }, [_vm._v("Package")]), _c('div', [_c('MaxText', {
    attrs: {
      "text": _vm.getIOSReleaseInfo.package,
      "max": "15"
    }
  })], 1)])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_c('div', {
    staticClass: "bold-text mb-2"
  }, [_vm._v("Version")]), _c('div', [_c('MaxText', {
    attrs: {
      "text": _vm.getIOSReleaseInfo.version,
      "max": "18"
    }
  })], 1)])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_c('div', {
    staticClass: "bold-text mb-2"
  }, [_vm._v("Version Code")]), _c('div', [_c('MaxText', {
    attrs: {
      "text": _vm.getIOSReleaseInfo.version_code,
      "max": "18"
    }
  })], 1)])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "bold-text mb-2"
  }, [_vm._v("Size")]), _c('div', [_vm._v(" " + _vm._s(_vm.formatBytes(_vm.getIOSReleaseInfo.size)) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_c('div', {
    staticClass: "bold-text mb-2"
  }, [_vm._v("Released")]), _c('div', [_c('vue-hoverable-date', {
    attrs: {
      "date": _vm.getIOSReleaseInfo.created_at
    }
  })], 1)])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_c('div', {
    staticClass: "bold-text mb-2"
  }, [_vm._v("Supported Platforms")]), _vm._l(_vm.get(_vm.getIOSReleaseInfo, 'info.supported_platforms', []), function (item, sindex) {
    return _c('div', {
      key: sindex
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  })], 2)]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_c('div', {
    staticClass: "bold-text mb-2"
  }, [_vm._v("Minimum OS Version")]), _c('div', [_vm._v(" " + _vm._s(_vm.get(_vm.getIOSReleaseInfo, "info.minimum_os_version", "")) + " ")])])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_c('div', {
    staticClass: "bold-text mb-2"
  }, [_vm._v("Platform Version")]), _c('div', [_vm._v(" " + _vm._s(_vm.get(_vm.getIOSReleaseInfo, "info.platform_version", "-")) + " ")])])])]], 2)], 1) : _vm._e()], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "bodyFont bold-text text-muted"
  }, [_vm._v("General Info")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "bold-text mb-2"
  }, [_vm._v("Developer")]), _c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.get(_vm.installInfo, "settings.developer_contact.name", "")) + " ")]), _c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.get(_vm.installInfo, "settings.developer_contact.email", "")) + " ")]), _c('div', [_vm.get(_vm.installInfo, 'settings.developer_contact.website', '') ? _c('a', {
    attrs: {
      "href": _vm.get(_vm.installInfo, 'settings.developer_contact.website', '')
    }
  }, [_vm._v(" Website ")]) : _vm._e()])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "bold-text mb-2"
  }, [_vm._v("App Legal")]), _vm.get(_vm.installInfo, 'settings.developer_contact.terms', '') ? _c('div', {
    staticClass: "mb-2"
  }, [_vm.get(_vm.installInfo, 'settings.developer_contact.terms', '') ? _c('a', {
    attrs: {
      "href": _vm.get(_vm.installInfo, 'settings.developer_contact.terms', '')
    }
  }, [_vm._v(" Terms & Conditions ")]) : _vm._e()]) : _vm._e(), _vm.get(_vm.installInfo, 'settings.developer_contact.privacy', '') ? _c('div', [_vm.get(_vm.installInfo, 'settings.developer_contact.privacy', '') ? _c('a', {
    attrs: {
      "href": _vm.get(_vm.installInfo, 'settings.developer_contact.privacy', '')
    }
  }, [_vm._v(" Privacy policy ")]) : _vm._e()]) : _vm._e(), !_vm.get(_vm.installInfo, 'settings.developer_contact.privacy', '') && !_vm.get(_vm.installInfo, 'settings.developer_contact.terms', '') ? _c('div', [_vm._v(" Not provided ")]) : _vm._e()])], 1), _c('v-row', {
    staticClass: "mt-8 faq",
    attrs: {
      "id": "releaseFaq",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "questions",
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('h1', {
    staticClass: "font-size-h2 bold-text"
  }, [_vm._v("FAQ")]), _c('br'), _c('v-expansion-panels', {
    attrs: {
      "flat": ""
    }
  }, [['android', 'unknown'].includes(_vm.mobile) ? [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "py-2 px-4",
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "ml-4",
          attrs: {
            "large": "",
            "left": ""
          }
        }, [_vm._v("expand_more")])];
      },
      proxy: true
    }], null, false, 3069909119)
  }, [_c('div', {
    staticClass: "font-size-h3 font-weight-light"
  }, [_vm._v(" While installing the app on Android, I get a security warning ")])]), _c('v-expansion-panel-content', [_c('div', [_vm._v(" On Android 8 or higher, you get a dialog such as "), _c('b', [_vm._v("\"Chrome, For your security, your phone is not allowed to install unknown apps from this source\"")]), _vm._v(". "), _c('br'), _c('br'), _vm._v(" Click the "), _c('b', [_vm._v("Settings")]), _vm._v(" button of that dialog and toggle "), _c('b', [_vm._v("Allow from this source")]), _vm._v(". "), _c('br'), _c('br'), _vm._v(" Alternatively you can navigate to "), _c('b', [_vm._v("Phone Settings > Apps & notifications")]), _vm._v(". "), _c('br'), _c('br'), _vm._v(" Find the browser application in the list, tap "), _c('b', [_vm._v("Advanced")]), _vm._v(" then "), _c('b', [_vm._v("Install unknown apps")]), _vm._v(" then toggle "), _c('b', [_vm._v("Allow from this source")]), _vm._v(". "), _c('br'), _c('br'), _vm._v(" On Android 7 or lower, navigate to the device "), _c('b', [_vm._v("Settings")]), _vm._v(" then into the "), _c('b', [_vm._v("Security")]), _vm._v(" category. Under the "), _c('b', [_vm._v("Device Administration")]), _vm._v(" category, toggle "), _c('b', [_vm._v("Unknown Sources")]), _vm._v(". Then try to install the application again. Please note that some device manufacturers might customize the settings UI and thus menu entries can have different names or be at a different location. ")])])], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "py-2 px-4",
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "ml-4",
          attrs: {
            "large": "",
            "left": ""
          }
        }, [_vm._v("expand_more")])];
      },
      proxy: true
    }], null, false, 3069909119)
  }, [_c('div', {
    staticClass: "font-size-h3 font-weight-light"
  }, [_vm._v(" While installing the app, I get a \"There Was a Problem Parsing the Package\" error ")])]), _c('v-expansion-panel-content', [_c('div', [_vm._v(" This error could be caused by one of the following reasons: "), _c('br'), _c('br'), _c('ul', [_c('li', [_vm._v("File may be downloaded incompletely")]), _c('li', [_vm._v(" Application might be not suitable for your hardware or OS version ")]), _c('li', [_vm._v("Security settings")]), _c('li', [_vm._v("Corrupted APK file")]), _c('li', [_vm._v(" Name of the package is changed after signing ")])]), _c('br'), _c('p', [_vm._v(" To troubleshoot, try reinstalling the app. If that doesn't work, contact the app developer to help resolve the issue. ")])])])], 1)] : _vm._e(), ['ios', 'unknown'].includes(_vm.mobile) ? [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "py-2 px-4",
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "ml-4",
          attrs: {
            "large": "",
            "left": ""
          }
        }, [_vm._v("expand_more")])];
      },
      proxy: true
    }], null, false, 3069909119)
  }, [_c('div', {
    staticClass: "font-size-h3 font-weight-light"
  }, [_vm._v(" I get the message “Untrusted Enterprise Developer” ")])]), _c('v-expansion-panel-content', [_c('div', [_vm._v(" In order for the app to be available for use, you will need to trust the developer's certificate. "), _c('br'), _c('br'), _vm._v(" From your phone’s home screen, tap "), _c('b', [_vm._v("Settings > General > Profiles or Profiles & Device Management")]), _vm._v(". "), _c('br'), _vm._v("Under the "), _c('b', [_vm._v("Enterprise App")]), _vm._v(" heading, you see will see a profile for the developer. Tap the name of the developer profile and then confirm you trust them. You should now be able to launch the app. ")])])], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "py-2 px-4",
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "ml-4",
          attrs: {
            "large": "",
            "left": ""
          }
        }, [_vm._v("expand_more")])];
      },
      proxy: true
    }], null, false, 3069909119)
  }, [_c('div', {
    staticClass: "font-size-h3 font-weight-light"
  }, [_vm._v(" While installing the app, I get an “Unable to Download App” error, and the app is not installed ")])]), _c('v-expansion-panel-content', [_c('div', [_vm._v(" This error could be caused by many reasons, and you should contact the app developer to help resolve the issue."), _c('br'), _c('br'), _vm._v(" If you are the app developer, this error could be caused by one of the following reasons: "), _c('br'), _c('ul', [_c('li', [_vm._v(" UDID is not included in the provisioning profile ")]), _c('li', [_vm._v(" The build was incorrectly signed or has broken entitlements ")]), _c('li', [_vm._v(" Device or iOS version is incompatible with the build ")]), _c('li', [_vm._v("Device storage is full")]), _c('li', [_vm._v("Actual download error")]), _c('li', [_vm._v(" App is over 50 MB and downloaded over a cellular connection ")])])])])], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "py-2 px-4",
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "ml-4",
          attrs: {
            "large": "",
            "left": ""
          }
        }, [_vm._v("expand_more")])];
      },
      proxy: true
    }], null, false, 3069909119)
  }, [_c('div', {
    staticClass: "font-size-h3 font-weight-light"
  }, [_vm._v(" After installing the app, It appears to download successfully but I am unable to open it ")])]), _c('v-expansion-panel-content', [_c('div', [_vm._v(" It could be because your UDID is missingg from provisioning profile. "), _c('br'), _vm._v(" Go to "), _c('a', {
    attrs: {
      "href": "/udid"
    }
  }, [_vm._v("install UDID page")]), _vm._v(" in safari and continue from there ")])])], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "py-2 px-4",
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "ml-4",
          attrs: {
            "large": "",
            "left": ""
          }
        }, [_vm._v("expand_more")])];
      },
      proxy: true
    }], null, false, 3069909119)
  }, [_c('div', {
    staticClass: "font-size-h3 font-weight-light"
  }, [_vm._v(" When I press the “Install” button, the installation alert never shows up and the app never installs on my device ")])]), _c('v-expansion-panel-content', [_c('div', [_vm._v(" If you have previously installed the app from the App Store, and it carries the same version of the app that is being installed, there will be no alert to confirm the installation."), _c('br'), _c('br'), _vm._v(" As a workaround, please try uninstalling the existing version of the app, and then tap the Install button again. ")])])], 1)] : _vm._e()], 2)], 1)], 1)], 1)], 1)], 1), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.qrcodeModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('install-qr-code', {
          attrs: {
            "android-release-info": _vm.getAndroidReleaseInfo,
            "image": _vm.getAppImage(),
            "install-info": _vm.installInfo,
            "ios-release-info": _vm.getIOSReleaseInfo,
            "share-link": _vm.shareLink,
            "from-modal": ""
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.qrcodeModal,
      callback: function callback($$v) {
        _vm.qrcodeModal = $$v;
      },
      expression: "qrcodeModal"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "width": "600"
    },
    on: {
      "close": function close($event) {
        _vm.openPermissionModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm.openPermissionModal ? _c('v-row', {
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          staticClass: "mt-4 text-center",
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "titleFont bold-text"
        }, [_vm._v(" " + _vm._s(_vm.installInfo.name) + " ")]), _c('div', {
          staticClass: "mt-4 bodyFont"
        }, [_c('span', {
          staticClass: "text-muted mr-2"
        }, [_vm._v("By")]), _c('span', [_vm._v(_vm._s(_vm.get(_vm.installInfo, "settings.developer_contact.name", "")))])])]), _c('v-col', {
          staticClass: "black--text scrollable",
          attrs: {
            "cols": "12"
          }
        }, [_c('permission-mapper', {
          attrs: {
            "permissions": _vm.getAndroidReleaseInfo.info.permissions
          }
        })], 1)], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openPermissionModal,
      callback: function callback($$v) {
        _vm.openPermissionModal = $$v;
      },
      expression: "openPermissionModal"
    }
  }), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.showRequestForm = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('release-request-form', {
          attrs: {
            "android": _vm.getAndroidReleaseInfo,
            "ios": _vm.getIOSReleaseInfo
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showRequestForm,
      callback: function callback($$v) {
        _vm.showRequestForm = $$v;
      },
      expression: "showRequestForm"
    }
  }), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.openSubscribeModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm.openSubscribeModal ? _c('subscribe-form', {
          on: {
            "close": function close($event) {
              _vm.openSubscribeModal = false;
            },
            "success": function success($event) {
              _vm.subscribedMessage = $event;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openSubscribeModal,
      callback: function callback($$v) {
        _vm.openSubscribeModal = $$v;
      },
      expression: "openSubscribeModal"
    }
  }), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-card-title', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Progress in background ")]), _c('v-card-text', {
          staticClass: "message"
        }, [_vm._v(" Once you tap on install button, you can check the progress in your "), _c('b', [_vm._v("home screen")]), _vm._v(". ")]), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          staticClass: "main primary",
          on: {
            "click": function click($event) {
              _vm.openIosInstallHelp = false;
            }
          }
        }, [_vm._v(" Ok ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openIosInstallHelp,
      callback: function callback($$v) {
        _vm.openIosInstallHelp = $$v;
      },
      expression: "openIosInstallHelp"
    }
  }), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.androidInstallConfirmation = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', [_c('div', {
          staticClass: "bold-text subListFont"
        }, [_vm._v(" Do you trust this developer? ")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.androidInstallConfirmation = false;
            }
          }
        })], 1), _c('v-card-text', [_c('div', {
          staticClass: "bodyFont darkGrey--text"
        }, [_vm._v(" TestApp.io did not scan this application from viruses or malware."), _c('br'), _c('br'), _vm._v(" If you "), _c('b', [_vm._v("trust this developer")]), _vm._v(", please go ahead and install this application."), _c('br'), _c('br'), _vm._v(" TestApp.io is not responsible for any damage or harm that this application may case to you or your device."), _c('br'), _c('br'), _vm._v(" That doesn't mean that this application is harmful, we just want to make sure that you are aware of the risks. "), _c('hr', {
          staticClass: "my-4"
        }), _c('b', [_vm._v("So... what is TestApp.io?")]), _vm._v(" "), _c('br'), _c('br'), _vm._v(" It's a platform that allows developers to easily share their applications with anyone for testing and feedback before releasing to Android & Apple stores. "), _c('br'), _c('br'), _vm._v("TestApp.io is not a replacement for the Android & Apple stores and does not own this application. "), _c('br'), _c('br'), _vm._v(" For more info, please contact the person who shared this link with you. ")])]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.androidInstallConfirmation = false;
            }
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary"
          },
          on: {
            "click": _vm.setConfirmationInStorage
          }
        }, [_vm._v(" Okay, got it! ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.androidInstallConfirmation,
      callback: function callback($$v) {
        _vm.androidInstallConfirmation = $$v;
      },
      expression: "androidInstallConfirmation"
    }
  }), _c('v-dialog', {
    attrs: {
      "fullscreen": "",
      "hide-overlay": "",
      "persistent": ""
    },
    model: {
      value: _vm.showPasswordModal,
      callback: function callback($$v) {
        _vm.showPasswordModal = $$v;
      },
      expression: "showPasswordModal"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "primary"
    }
  }, [_c('v-toolbar', {
    attrs: {
      "color": "primary",
      "dark": "",
      "flat": ""
    }
  }, [_c('v-spacer')], 1), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-15",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "titleFont white--text mb-5"
  }, [_vm._v(" " + _vm._s(_vm.passwordInformation.message) + " ")]), _c('v-card', {
    staticClass: "pa-4"
  }, [_c('v-row', {
    staticClass: "darkGrey--text"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-avatar', {
    attrs: {
      "height": "80",
      "tile": "",
      "width": "80"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.passwordInformation.image || '/media/logos/lock.svg',
      "contain": ""
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "titleFont",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.passwordInformation.name) + " ")]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|max:30|min:3',
      expression: "'required|max:30|min:3'"
    }],
    attrs: {
      "counter": "30",
      "filled": "",
      "label": "Enter your password",
      "type": "password"
    },
    model: {
      value: _vm.protectedPassword,
      callback: function callback($$v) {
        _vm.protectedPassword = $$v;
      },
      expression: "protectedPassword"
    }
  }, 'v-text-field', _vm.veeValidate('Password', 'Enter your password'), false))], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none pa-7",
    attrs: {
      "disabled": _vm.$validator.errors.any(),
      "loading": _vm.verifyPasswordLoading,
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.verifyPassword,
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.verifyPassword.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "font14"
  }, [_vm._v("Submit")])])], 1)], 1)], 1), _c('div', {
    staticClass: "mt-4 bodyFont white--text"
  })], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "mt-15 pt-15"
  }, [_c('v-spacer'), _c('div', {
    staticClass: "text-center white--text"
  }, [_c('v-img', {
    staticClass: "mb-3 mx-auto",
    attrs: {
      "contain": "",
      "height": "20",
      "src": "/media/logos/logo.svg",
      "width": "100"
    }
  }), _vm._v(" By installing this app you're agreeing with TestApp.io "), _c('br'), _c('a', {
    staticClass: "white--text text-decoration-underline",
    attrs: {
      "href": "https://testapp.io/privacy-policy"
    }
  }, [_vm._v("Privacy Policy ")]), _vm._v("   and   "), _c('a', {
    staticClass: "white--text text-decoration-underline",
    attrs: {
      "href": "https://testapp.io/terms-and-conditions"
    }
  }, [_vm._v("Terms & Conditions")])], 1), _c('v-spacer')], 1)], 1)], 1)], 1), _vm._m(0)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-5 mb-12 text-center"
  }, [_c('div', [_c('div', {
    staticClass: "mb-5"
  }, [_c('a', {
    attrs: {
      "href": "https://testapp.io"
    }
  }, [_c('img', {
    attrs: {
      "height": "30",
      "src": "/media/logos/logo_dark.svg"
    }
  })])]), _c('div', {
    staticClass: "text-muted"
  }, [_vm._v(" By installing this app you're agreeing with TestApp.io "), _c('br'), _c('a', {
    staticClass: "black--text text-decoration-underline",
    attrs: {
      "href": "https://testapp.io/privacy-policy"
    }
  }, [_vm._v("Privacy Policy ")]), _vm._v("   and   "), _c('a', {
    staticClass: "black--text text-decoration-underline",
    attrs: {
      "href": "https://testapp.io/terms-and-conditions"
    }
  }, [_vm._v("Terms & Conditions")])])])]);

}]

export { render, staticRenderFns }