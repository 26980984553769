<template>
    <v-row>
        <v-col class="font-size-h3 black--text bold-text mt-4" cols="12">
            Subscribe for updates
        </v-col>
        <v-col cols="12">
            <div class="smallFont mb-1 bold-text">
                Name
            </div>
            <v-text-field v-model="form.name" filled counter="20" dense v-validate="'max:20|min:3|required'" v-bind="veeValidate('name', '')">
            </v-text-field>
        </v-col>
        <v-col cols="12">
            <div class="smallFont mb-1 bold-text">
                Email
            </div>
            <v-text-field v-model="form.email" filled dense v-validate="'required|email'" v-bind="veeValidate('email', '')">
            </v-text-field>
        </v-col>
        <v-col cols="12">
            <div class="bodyFont bold-text mb-2">Subscribe for</div>
            <v-btn-toggle mandatory v-model="selectedPlatforms" multiple>
                <v-btn :class="selectedPlatforms.includes('android') ? 'white--text' : ''" class="text-transform-none"
                    :color="selectedPlatforms.includes('android') ? 'primary' : ''" value="android">
                    <v-icon :color="selectedPlatforms.includes('android') ? 'white' : ''" left>mdi-android</v-icon>
                    Android
                </v-btn>
                <v-btn :class="selectedPlatforms.includes('ios') ? 'white--text' : ''" class="text-transform-none"
                    :color="selectedPlatforms.includes('ios') ? 'primary' : ''" value="ios">
                    <v-icon :color="selectedPlatforms.includes('ios') ? 'white' : ''" left>mdi-apple</v-icon>
                    iOS
                </v-btn>
            </v-btn-toggle>
        </v-col>
        <v-col v-if="errorMessage" cols="12">
            <v-alert color="error" outlined>
                {{ errorMessage }}
            </v-alert>
        </v-col>
        <v-col class="text-right" cols="12">
            <v-btn @click="$emit('close')" text color="primary" :disabled="busy" class="text-transform-none mx-2" depressed>
                Cancel
            </v-btn>
            <v-btn @click="subscribeForUpdates" color="primary" :loading="busy" class="text-transform-none" depressed>
                Subscribe
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import { SUBMIT_SUBSCRIBERS } from "@/store/apps/subscribers.module";

export default {
    mixins: [veeValidate],
    data() {
        return {
            subscribed: false,
            form: {
                email: "",
                name: "",
                platform: "",
            },
            selectedPlatforms: [],
            errorMessage: null,
            busy: false,
        };
    },
    created() {
        const mobile = this.getMobileOperatingSystem;
        if (mobile === "android") {
            this.selectedPlatforms = ["android"];
        }
        if (mobile === "ios") {
            this.selectedPlatforms = ["ios"];
        }
        if (mobile === "unknown") {
            this.selectedPlatforms = ["android", "ios"];
        }
    },
    methods: {
        async subscribeForUpdates() {
            if (await this.validateAllFields()) {
                this.errorMessage = null;
                if (this.busy) return;
                this.busy = true;
                if (this.selectedPlatforms && this.selectedPlatforms.length) {
                    this.form.platform =
                        this.selectedPlatforms.length == 2
                            ? "both"
                            : this.selectedPlatforms[0];
                }
                const uid = this.$route.params.uid;
                this.$store
                    .dispatch(SUBMIT_SUBSCRIBERS, {
                        uid,
                        ...this.form,
                    })
                    .then((response) => {
                        this.busy = false;
                        this.subscribed = true;
                        this.subscribeEmail = "";
                        this.$validator.errors.clear();
                        this.$emit("close");
                        this.$emit("success", response.data.message);
                        setTimeout(() => {
                            this.subscribed = false;
                            this.$validator.errors.clear();
                        }, 3000);
                    })
                    .catch((err) => {
                        this.busy = false;
                        this.errorMessage = err.message;
                    });
            }
        },
    },
};
</script>
