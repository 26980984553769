var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    staticClass: "font-size-h3 black--text bold-text mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Subscribe for updates ")]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "smallFont mb-1 bold-text"
  }, [_vm._v(" Name ")]), _c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:20|min:3|required',
      expression: "'max:20|min:3|required'"
    }],
    attrs: {
      "filled": "",
      "counter": "20",
      "dense": ""
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', _vm.veeValidate('name', ''), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "smallFont mb-1 bold-text"
  }, [_vm._v(" Email ")]), _c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|email',
      expression: "'required|email'"
    }],
    attrs: {
      "filled": "",
      "dense": ""
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }, 'v-text-field', _vm.veeValidate('email', ''), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "bodyFont bold-text mb-2"
  }, [_vm._v("Subscribe for")]), _c('v-btn-toggle', {
    attrs: {
      "mandatory": "",
      "multiple": ""
    },
    model: {
      value: _vm.selectedPlatforms,
      callback: function callback($$v) {
        _vm.selectedPlatforms = $$v;
      },
      expression: "selectedPlatforms"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    class: _vm.selectedPlatforms.includes('android') ? 'white--text' : '',
    attrs: {
      "color": _vm.selectedPlatforms.includes('android') ? 'primary' : '',
      "value": "android"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.selectedPlatforms.includes('android') ? 'white' : '',
      "left": ""
    }
  }, [_vm._v("mdi-android")]), _vm._v(" Android ")], 1), _c('v-btn', {
    staticClass: "text-transform-none",
    class: _vm.selectedPlatforms.includes('ios') ? 'white--text' : '',
    attrs: {
      "color": _vm.selectedPlatforms.includes('ios') ? 'primary' : '',
      "value": "ios"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.selectedPlatforms.includes('ios') ? 'white' : '',
      "left": ""
    }
  }, [_vm._v("mdi-apple")]), _vm._v(" iOS ")], 1)], 1)], 1), _vm.errorMessage ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    attrs: {
      "color": "error",
      "outlined": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")])], 1) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none mx-2",
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": _vm.busy,
      "depressed": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "loading": _vm.busy,
      "depressed": ""
    },
    on: {
      "click": _vm.subscribeForUpdates
    }
  }, [_vm._v(" Subscribe ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }